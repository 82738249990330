import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom'

const links = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'About Us',
    path: '/about'
  },
  {
    label: 'Services',
    path: '#'
  },
  {
    label: 'Contact',
    path: '/contact'
  }
]

function Header({ className = "menu-area-three" }) {
  const [drawer, setDrawer] = useState(false);
  const navigate = useNavigate()
  const location = useLocation();
  
 
  

  useEffect(() => {
    if (drawer) {
      document.body.classList.add("mobile-menu-visible");
    } else {
      document.body.classList.remove("mobile-menu-visible");
    }
  }, [drawer]);

 


  return (
    <header>
      <div id="header-fixed-height"></div>
      <div id="sticky-header" className={`menu-area  ${className || ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                onClick={() => setDrawer(!drawer)}
                className="mobile-nav-toggler"
              >
                <i className="fas fa-bars"></i>
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div className="logo">
                    <a href="/">
                      <img
                        src={require(`../../../assets/img/logo/logo_new.png`)}
                        alt="Logo"
                        // style={{ height: 30}}
                      />
                    </a>
                  </div>
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      {links.map(item => (
                        <li key={item.path} onClick={() => navigate(item.path)} className={location.pathname === item.path ? "active" : ''}>
                          <a>
                            {item.label}
                          </a>
                        </li>
                      ))}
                  
                    </ul>
                  </div>
                  <div className="header-action d-none d-md-block">
                    <ul className="list-wrap">
                      <li onClick={() => navigate('/contact')} className="header-btn">
                        <a className="btn">
                          Get a Quote
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div className="mobile-menu">
                <nav className="menu-box">
                  <div onClick={() => setDrawer(!drawer)} className="close-btn">
                    <i className="fas fa-times"></i>
                  </div>
                  <div className="nav-logo">
                    <a href="/">
                      <img
                        src={require(`../../../assets/img/logo/logo_new.png`)}
                        alt="Logo"
                      />
                    </a>
                  </div>
                  <div className="menu-outer">
                    <ul className="navigation">
                     {links.map(item => (
                        <li key={item.path} onClick={() => navigate(item.path)} className={location.pathname === item.path ? "active" : ''}>
                          <a>
                            {item.label}
                          </a>
                        </li>
                     ))}
                      </ul>
                  </div>
                
                </nav>
              </div>
              <div className="menu-backdrop"></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header
