import React from "react";
import FooterOne from "../components/Layouts/Footers/FooterOne";
import InnerPageTitle from "../components/Helpers/InnerPageTitle";
import AboutArea from "../components/Home/AboutArea";
import WorkArea from "../components/About/WorkArea";
import Header from "../components/Layouts/Headers/Header";

function index() {
  return (
    <>
      <Header />
      <InnerPageTitle
        title="About Us"
        paths={[
          { name: "Home", path: "/" },
          { name: "About", path: "/about" },
        ]}
      />
      <AboutArea className="pt-120" />
      <WorkArea />
      <FooterOne />
    </>
  );
}

export default index;
