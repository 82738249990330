import React, { useState } from 'react';
import FooterOne from '../components/Layouts/Footers/FooterOne';
import Hero from '../components/Home/Hero';
import AboutArea from '../components/Home/AboutArea';
import WorkArea from '../components/About/WorkArea';
import PopupVideo from '../components/Helpers/PopupVideo';
import Header from '../components/Layouts/Headers/Header';

function Home() {
  const [videoPop, setVideoPop] = useState(false);
  const videoHandler = (e) => {
    e.preventDefault();
    setVideoPop(!videoPop);
  };
  return (
    <>
      {videoPop && (
        <PopupVideo
          handler={videoHandler}
          videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
        />
      )}
      <Header />
      <main>
        <Hero />
        <AboutArea />
        <WorkArea />
      </main>
      <FooterOne />
    </>
  );
}

export default Home;
