import React from "react";

function WorkArea() {
  return (
    <section className="work-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-50">
              <span className="sub-title">Working Plan</span>
              <h2 className="title">Roof Estimation Working Process</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/work_img01.png`)}
                  alt=""
                />
                <h4 className="number">01</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Consultation & Assessment</h2>
                <p>
                  Understand project needs and evaluate the roofing area.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/work_img02.png`)}
                  alt=""
                />
                <h4 className="number">02</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Data Collection & Input</h2>
                <p>
                Gather measurements and input data into Xactimate or AppliCad.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/work_img03.png`)}
                  alt=""
                />
                <h4 className="number">03</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Estimation & Diagram Creation</h2>
                <p>
                 Generate detailed cost estimates and precise roof diagrams.


                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/work_img04.png`)}
                  alt=""
                />
                <h4 className="number">04</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Review & Delivery</h2>
                <p>
                Verify accuracy and deliver final documents in PDF format.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WorkArea;
