import React from "react";

function AboutArea({ className }) {
  return (
    <section className={`about-area pb-120 ${className || ""}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-7 col-lg-6 order-0 order-lg-2">
            <div className="about-img-wrap">
              <img
                src={require(`../../assets/img/banner/about1.png`)}
                alt=""
                className="wow fadeInRight"
                data-wow-delay=".4s"
              />
              <img
                src={require(`../../assets/img/banner/about2.png`)}
                alt=""
                className="wow fadeInRight"
                data-wow-delay=".2s"
              />
              <div className="about-experiences-wrap">
                <div className="experiences-item">
                  <div className="icon">
                    <img
                      src={
                        require(`../../assets/img/icon/about_icon01.svg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <h6 className="title">
                      We have more than 5 years of experiences
                    </h6>
                  </div>
                </div>
                <div className="experiences-item">
                  <div className="icon">
                    <img
                      src={
                        require(`../../assets/img/icon/about_icon02.svg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <h6 className="title">
                      We use professional and experienced person
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="about-content">
              <div className="section-title mb-25 tg-heading-subheading animation-style3">
                <span className="sub-title tg-element-title">
                  About Our Company
                </span>
                <h2 className="title tg-element-title">
                  We’re Committed to Roofing Estimation Excellence
                </h2>
              </div>
              <p>
               Our service provides precise, affordable, and rapid roof diagrams crafted using Xactimate (ESX) and AppliCad (XML). We ensure high-quality results and deliver all diagrams in an easily accessible PDF format, making your roofing projects more efficient and streamlined.
              </p>
              <div className="about-list">
                <ul className="list-wrap">
                  <li>
                    <i className="fas fa-check"></i>ESX, XML and PDF
                  </li>
                  <li>
                    <i className="fas fa-check"></i>Professional and experienced
                    human resources.
                  </li>
                  <li>
                    <i className="fas fa-check"></i>Provide the best roof
                    estimation
                  </li>
                </ul>
              </div>
              <a href="/about" className="btn">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutArea;
