import React from "react";

function MainWid() {
  return (
    <section className="contact-area pt-60 pb-40">
      <div className="container">
        <div className="row justify-content-center">
          {/* <div className="col-xl-6 col-lg-10">
            <div
              className="contact-form-wrap"
              style={{
                backgroundImage: `url(${require("../../assets/img/images/contact_form_bg.jpg")})`,
              }}
            >
              <h2 className="title">Contact With Us</h2>
              <p>Send us a message and we' ll respond as soon as possible</p>
              <form action="#" className="contact-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="firstName"
                        type="text"
                        placeholder="First Name*"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="lastName"
                        type="text"
                        placeholder="Last Name*"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="email"
                        type="email"
                        placeholder="Email Address*"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="phone"
                        type="text"
                        placeholder="Phone Number*"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-grp">
                  <input id="subject" type="text" placeholder="Subject" />
                </div>
                <div className="form-grp">
                  <textarea
                    id="message"
                    placeholder="Your Message here"
                  ></textarea>
                </div>
                <button className="btn" type="submit">
                  Send Message
                </button>
              </form>
            </div>
          </div> */}
          <div className="col-xl-6 col-lg-10">
            <div className="contact-info-wrap">
              <h2 className="title">Need Any Help?</h2>
              <p>Call us or message and we' ll respond as soon as possible</p>
              <ul className="list-wrap">
                <li>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-phone-alt"></i>
                    </div>
                    <div className="content">
                      <a href="tel:0123456789">+(91) 7760197043</a>
                     
                    </div>
                  </div>
                </li>
                <li>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div className="content">
                      <a href="mailto:info@jatayuestimation.com">info@jatayuestimation.com</a>
                    </div>
                  </div>
                </li>
                {/* <li>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                    <div className="content">
                      <p>
                        4517 Washington Ave. 32 <br /> Manchester, Road USA
                      </p>
                    </div>
                  </div>
                </li> */}
              </ul>
            </div>
            {/* <div id="contact-map">
            <iframe
              //   title="map"
              //   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96811.54759587669!2d-74.01263924803828!3d406880494567041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f131!3m3!1m2!1s0x89c25bae694479a3%3A0xb9949385da52e69e!2sBarclays%20Center!5e0!3m2!1sen!2sbd!4v1636195194646!5m2!1sen!2sbd"
              //   allowFullScreen
              //   loading="lazy"
              // ></iframe> 
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
